import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
// import Krono from 'krono';
import Krono from '../../krono/bin/krono.js';
import isMobile from 'ismobilejs';
import post from './keyframes/post';
import fovDesktop from './keyframes/fov-desktop';
import fovMobile from './keyframes/fov-mobile';

class Rusindoor {
  krono: Krono;
  afterSceneLoaded: any;
  canvasContainer: any;
  videoElements: HTMLVideoElement[] = [];

  // сколько всего файлов, аудио, моделей и текстур будет загружено
  // не использую totalItems потому с самого начала неизвестно кол-во ресурсов, т.к.,
  // например, текстуры находятся внутри моделей
  public totalResourcesCount = 78;

  isMobile = isMobile().any;
  isMobileMenuOpened: boolean = false;

  cubeCamera1: any;

  // { mesh, anchor }
  videoMeshesToUpdate = [];

  scrollAnimationElements = [];
  scrollAnimationBaseClass = 'scroll-animation';
  screensElement: any;
  screenEndElement: any;

  materials = {
    'acrilic_panel_02': {
      transparent: true,
      opacity: 0.9,
      roughness: 0.4,
      metalness: 0.05,
      envMapIntensity: 0.1
    },

    'aluminium_door_01': {
      roughness: 0.5,
      metalness: 1
    },

    'aluminium_frame_window_02': {
      roughness: 0.5,
      metalness: 1
    },

    'aluminium_mount_01': {
      roughness: 0.2,
      metalness: 0.8
    },

    'aluminium_mount_02': {
      roughness: 0.2,
      metalness: 0.8
    },

    'black_miror': {
      roughness: 0
    },

    'celing_01': {
      roughness: 1,
      metalness: 0
    },

    'celing_02': {
      roughness: 1,
      metalness: 0
    },

    'celing_03': {
      roughness: 1,
      metalness: 0
    },

    'celing_04': {
      roughness: 1,
      metalness: 0
    },

    'celing_panel_01': {
      roughness: 1,
      metalness: 0
    },

    'chair_010': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 0.7
    },

    'chair_013': {
      roughness: 0.65,
      metalness: 0,
      envMapIntensity: 0
    },

    'chair_022': {
      roughness: 0.6,
      metalness: 0,
      envMapIntensity: 0
    },

    'chair_023': {
      roughness: 0.6,
      metalness: 0,
      envMapIntensity: 0
    },

    'chair_blue': {
      roughness: 0.9,
      metalness: 0,
      envMapIntensity: 0
    },

    'circle_light': {
      roughness: 0.37,
      metalness: 0.3,
      envMapIntensity: 0.3
    },

    'city_monitor': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 0.5
    },

    'door knob_01': {
      roughness: 0.25,
      metalness: 0.5,
      envMapIntensity: 0.05
    },

    'door_1': {
      roughness: 0.5,
      metalness: 0
    },

    'door_2': {
      roughness: 0.5,
      metalness: 0
    },

    'floor_01': {
      roughness: 0.0,
      metalness: 0.5,
      // envMapIntensity: 0,
      envMapIntensity: 0.5,
      normalScale: new THREE.Vector2(1, 1)
    },

    'floor_02': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 1,
      normalScale: new THREE.Vector2(1.5, 1.5)
    },
    
    'floor_03': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 0.01,
      normalScale: new THREE.Vector2(0.5, 0.5)
    },

    'floor_04': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0,
      normalScale: new THREE.Vector2(0.5, 0.5)
    },

    'frame_cabinet': {
      roughness: 0.3,
      metalness: 0.7,
      envMapIntensity: 0
    },

    'glass_02': {
      transparent: true,
      opacity: 0.05
    },

    'glass_05': {
      transparent: true,
      opacity: 0.1,
      roughness: 0.2,
      metalness: 0.05,
      envMapIntensity: 1
    },

    'glass_cabinet': {
      transparent: true,
      opacity: 0.25,
      roughness: 0.3,
      metalness: 0
    },

    'glass_table_2': {
      transparent: true,
      opacity: 0.5
    },

    'grid_beeg': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'L-01': {
      roughness: 0.3,
      metalness: 0.8,
      envMapIntensity: 0.1
    },

    'LC_tube_012': {
      roughness: 0.1,
      metalness: 0.5
    },

    'LR-015': {
      roughness: 0.1,
      metalness: 0.8
    },

    'lift_cabin_02': {
      roughness: 0.5,
      metalness: 0,
      envMapIntensity: 0
    },

    'lift_floor': {
      roughness: 0.5,
      metalness: 0,
      envMapIntensity: 0
    },

    'lift_wall': {
      roughness: 0.35,
      metalness: 0.7,
      envMapIntensity: 0.3
    },

    'light_material': {
      side: THREE.DoubleSide,
      emissive: new THREE.Color(1, 1, 1)
    },

    'logotype': {
      emissive: new THREE.Color(0.35, 0.35, 0.35),
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'Material #1496': {
      roughness: 0.22,
      metalness: 0.32
    },

    'monitor_1': {
      roughness: 0.1,
      metalness: 0.1,
      envMapIntensity: 0.25
    },

    'monitor_2': {
      roughness: 0.1,
      metalness: 0.1,
      envMapIntensity: 0.25
    },

    'monitor_3': {
      roughness: 0.1,
      metalness: 0.1,
      envMapIntensity: 0.25
    },

    'monitor_4': {
      roughness: 0.1,
      metalness: 0.1,
      envMapIntensity: 0.25
    },

    'panel_lift_metal': {
      roughness: 0.08,
      metalness: 0.9
    },

    'Petra': {
      roughness: 0.7,
      metalness: 0,
      envMapIntensity: 0.15
    },
    
    'plant_01': {
      roughness: 0.35,
      metalness: 0,
      envMapIntensity: 0
    },
    
    'plant_03': {
      roughness: 0.35,
      metalness: 0,
      envMapIntensity: 0
    },

    'reseption': {
      roughness: 0.35,
      metalness: 0.7
    },

    // поменять?
    'reseption_grid_metal': {
      roughness: 0,
      metalness: 0.7
    },

    'reseption_red_back': {
      roughness: 0.35,
      metalness: 0.7,
      envMapIntensity: 0
    },

    'reseption_wall': {
      roughness: 1,
      metalness: 0.5,
      envMapIntensity: 0
    },

    'sofa_1': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'intertime_1191_liv_2_seater_sofa_wide_nofh': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'sofa_2': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'table_01': {
      transparent: true,
      opacity: 0.4
    },

    'table_white': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 0
    },

    'table_white_061': {
      roughness: 0.3,
      metalness: 0,
      envMapIntensity: 0
    },

    'tiled_wall_01': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'tiled_wall_02': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'tiled_wall_03': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_01': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_02': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_03': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_04': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_05': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_dekor': {
      roughness: 0.2,
      metalness: 0.5
    },

    'wall_panel_01': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0.05
    },

    'wall_panel_02': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0.05
    },

    'wall_panel_wood': {
      roughness: 0.8,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_pilaster_arc_01': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'wall_reception': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'EWom0318-HD2-O01P01-S-Var3': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    },

    'window back': {
      roughness: 1,
      metalness: 0,
      envMapIntensity: 0
    }
  };

  constructor(canvasContainer, scrollContainer, editorContainer, afterSceneLoaded) {
    this.krono = new Krono({
      canvasContainer: canvasContainer,
      scrollContainer: scrollContainer,
      editorContainer: editorContainer,
      projectName: 'rusindoor',
      debug: false,
      editor: false,
      cameraName: 'Camera002',
      cameraAnimationName: 'All Animations',
      useLocalKeyframes: false,
      debugFlightSpeed: 10,
      alpha: false,
      antialias: true,
      mainSceneFilename: `scene-${ (this.isMobile) ? 256 : 1024 }.glb`,
      mainSceneFolder: './assets/gltf/',
      onLoad: this.onLoad.bind(this),
      onProgress: this.onProgress.bind(this),
      keyframes: [((this.isMobile) ? fovMobile : fovDesktop), post]
    });

    this.canvasContainer = canvasContainer;
    this.afterSceneLoaded = afterSceneLoaded;

    this.krono.load();

    // простите
    document.querySelector('.menu .btn').addEventListener('click', this.onMenuBtnClick.bind(this));

    window.addEventListener('touchstart', this.updateHorizontalScroll.bind(this), { passive: false });
    window.addEventListener('touchmove', this.updateHorizontalScroll.bind(this), { passive: false });
    window.addEventListener('touchend', this.updateHorizontalScroll.bind(this), { passive: false });

    window.addEventListener('touchstart', this.playVideos.bind(this), { once: true });
  }

  updateHorizontalScroll(e?) {
    if (window.pageXOffset > 0) {
      window.scrollTo(0, window.pageYOffset);

      if (e) {
        e.preventDefault();
        e.stopImmediatePropagation();        
      }
    }
  }

  onLoad() {
    this.krono.renderer.toneMapping = THREE.LinearToneMapping;
    this.krono.optimizations.enabled = false;

    this.initScrollAnimation();

    document.querySelector('.preloader').classList.add('loaded');
    document.querySelector('.progress').classList.add('loaded');

    this.initPixelRatio();
    // this.initReflections();
    this.initEnvMap();
    this.setMaterialsProperties();

    this.canvasContainer.classList.add('loaded');

    this.initVideos();

    this.krono.camera.far = 215;
    this.krono.camera.updateProjectionMatrix();

    document.body.style.overflowY = 'auto';

    this.afterSceneLoaded();
  }

  onProgress(url, loaded, total) {
    let percent = loaded / this.totalResourcesCount;

    if (percent > 1) {
      percent = 1;
    }

    document.querySelector('.progress').textContent = Math.round(percent * 100) + '%';
  }

  playVideos() {
    this.videoElements.forEach(v => {
      v.play();
    });
  }

  // 3дс макс хуёво экспортирует ювишки, поэтому заменить текстуру на лету не получается
  // создаём объекты и даже анимируем их вручную, сразу с нужными текстурами
  initVideos() {
    // первый лайтбокс
    let video1 = this.createVideoTexture('video-1');
    let mesh1 = this.createVideoMesh(video1, THREE.PlaneBufferGeometry);
    mesh1.position.set(0.656, 1.304, 0.919);
    mesh1.rotation.set(0, -0.53, 0);
    mesh1.scale.set(1.03, 1.51, 1);

    // первый монитор
    let video2 = this.createVideoTexture('video-2');
    let mesh2 = this.createVideoMesh(video2, THREE.PlaneBufferGeometry);
    mesh2.position.set(-0.035, 2.480, -9.613);
    mesh2.scale.set(2.128, 1.186, 1.076);

    // второй монитор
    let video3 = this.createVideoTexture('video-3');
    let mesh3 = this.createVideoMesh(video3, THREE.PlaneBufferGeometry);
    mesh3.position.set(7.019, 2.191, -15.314);
    mesh3.scale.set(3.97, 1.973, 1);

    // левая дверца левого лифта
    let video4 = this.createImageTexture('lift-1.jpg');
    let mesh4 = this.createVideoMesh(video4, THREE.BoxBufferGeometry);
    mesh4.scale.set(0.059, 2.546, 0.6);

    this.videoMeshesToUpdate.push({
      mesh: mesh4,
      anchor: this.krono.scene.getObjectByName('lift_door_01')
    });

    // правая дверца левого лифта
    let video5 = this.createImageTexture('lift-2.jpg');
    let mesh5 = this.createVideoMesh(video5, THREE.BoxBufferGeometry);
    mesh5.scale.set(0.059, 2.546, 0.6);

    this.videoMeshesToUpdate.push({
      mesh: mesh5,
      anchor: this.krono.scene.getObjectByName('lift_door_02')
    });

    // левая дверца правого лифта
    let video6 = this.createImageTexture('lift-3.jpg');
    let mesh6 = this.createVideoMesh(video6, THREE.BoxBufferGeometry);
    mesh6.scale.set(0.059, 2.546, 0.6);

    this.videoMeshesToUpdate.push({
      mesh: mesh6,
      anchor: this.krono.scene.getObjectByName('lift_door_03')
    });

    // правая дверца правого лифта
    let video7 = this.createImageTexture('lift-4.jpg');
    let mesh7 = this.createVideoMesh(video7, THREE.BoxBufferGeometry);
    mesh7.scale.set(0.059, 2.546, 0.6);

    this.videoMeshesToUpdate.push({
      mesh: mesh7,
      anchor: this.krono.scene.getObjectByName('lift_door_04')
    });



    requestAnimationFrame(this.updateVideoMeshes.bind(this));
  }

  createImageTexture(filename): THREE.Texture {
    const texture = new THREE.TextureLoader().load('./assets/img/' + filename)
    texture.encoding = THREE.sRGBEncoding;

    return texture;
  }

  createVideoTexture(id): THREE.VideoTexture {
    const video: any = document.getElementById(id);

    const texture: any = new THREE.VideoTexture(video);
    // texture.wrapS = THREE.RepeatWrapping;
    // texture.wrapT = THREE.RepeatWrapping;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBFormat;
    texture.flipY = true;
    texture.encoding = THREE.sRGBEncoding;

    this.videoElements.push(video);

    return texture;
  }

  updateVideoMeshes() {
    this.videoMeshesToUpdate.forEach(data => {
      data.mesh.position.copy(data.anchor.position).divideScalar(10);
    });

    requestAnimationFrame(this.updateVideoMeshes.bind(this));
  }

  createVideoMesh(texture, geometryConstructor) {
    let geometry = new geometryConstructor();
    
    let material = new THREE.MeshBasicMaterial({
      map: texture
    });

    let mesh = new THREE.Mesh(geometry, material);
    
    this.krono.scene.add(mesh);

    return mesh;
  }

  initPixelRatio() {
    const d = window.devicePixelRatio;

    if (d >= 3 && this.isMobile) {
      this.krono.optimizations.setPixelRatio(2.5);
    } else if (d >= 2 && d <= 3) {
      this.krono.optimizations.setPixelRatio(1.25);
    }
  }

  initReflections() {
    // this.cubeCamera1 = new THREE.CubeCamera(1, 1000, 64);
    // this.cubeCamera1.position.set(-4.131, -2.482, 0.028);
    // this.krono.scene.add(this.cubeCamera1);

    // // @ts-ignore
    // // this.krono.scene.getObjectByName('floor_01').envMap = this.cubeCamera1.renderTarget.texture;

    // this.cubeCamera1.update(this.krono.renderer, this.krono.scene);
  }

  setMaterialsProperties() {
    this.krono.scene.traverse(obj => {
      if (obj.type === 'Mesh') {
        const mesh: any = obj;

        if (mesh.material && this.materials[mesh.material.name]) {
          let material = this.materials[mesh.material.name];

          Object.keys(material).forEach(k => {
            mesh.material[k] = material[k];
          });
        }
      }

      if (obj.type === 'PointLight') {
        (obj as any).intensity = 10.5;
      }
    });
  }

  initEnvMap() {
    let pmremGenerator = new THREE.PMREMGenerator(this.krono.renderer);
    pmremGenerator.compileEquirectangularShader();

    new RGBELoader()
      .setDataType(THREE.UnsignedByteType) // alt: FloatType, HalfFloatType
      // @ts-ignore
      .load('assets/env.hdr', (texture, textureData) => { // TODO: fix .ts
        let cubeRenderTarget = pmremGenerator.fromEquirectangular(texture);

        this.krono.scene.traverse(obj => {
          if (obj.type === 'Mesh' && (obj as any).material.type === 'MeshStandardMaterial') {

            // (obj as any).material.envMap = this.cubeCamera1.renderTarget.texture;
            (obj as any).material.envMap = cubeRenderTarget.texture;
            (obj as any).material.needsUpdate = true;
          }
        });

        // console.log(textureData);
        // console.log(texture);

        texture.dispose();
        pmremGenerator.dispose();
      });
  }

  initScrollAnimation() {
    this.scrollAnimationElements = Array.from(document.querySelectorAll('.' + this.scrollAnimationBaseClass));
    this.screensElement = document.querySelector('.screens');
    this.screenEndElement = document.querySelector('.screen.end');

    requestAnimationFrame(this.updateScrollAnimation.bind(this));
  }

  updateScrollAnimation() {
    requestAnimationFrame(this.updateScrollAnimation.bind(this));

    const wh = window.innerHeight;

    this.scrollAnimationElements.forEach(e => {
      const bounds = e.getBoundingClientRect();
      const top = bounds.top;
      const height = bounds.height;

      // сверху || снизу
      if (top + height > 0 && top < wh) {
        const y = top + height / 2;
        let percent = Math.sin(y / wh * 3); // в середине прозрачность 1, а ближе к граям 0

        if (percent < 0) {
          percent = 0;
        }

        e.style.opacity = percent.toFixed(4);
      }
    });

    // затемнение последнего экрана
    const endBounds = this.screenEndElement.getBoundingClientRect();
    let endY = endBounds.top * 0.5; // в два раза сильнее затемняем

    // нормализуем
    if (endY < 0) {
      endY = 0;
    }

    if (endY > wh) {
      endY = wh;
    }

    let opacity = endY / wh;
    const opacityLimit = 0.05;

    if (opacity < opacityLimit) {
      opacity = opacityLimit;
    }

    this.krono.renderer.toneMappingExposure = opacity;

    this.updateHorizontalScroll();
  }

  onMenuBtnClick(event) {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;

    // простите
    const menu = document.querySelector('.menu');

    if (this.isMobileMenuOpened) {
      menu.classList.add('active');
      event.target.classList.add('icon-cancel');
      event.target.classList.remove('icon-menu');
    } else {
      menu.classList.remove('active');
      event.target.classList.add('icon-menu');
      event.target.classList.remove('icon-cancel');
    }
  }
}

let rusindoor = new Rusindoor(
  document.querySelector('#canvas-container'),
  window,
  document.querySelector('#editor-container'),
  () => { console.log('afterSceneLoaded') }
);