export default [
  {
    "uuid": "E1830B32-96F2-4CE9-B768-F080490D03CD",
    "name": "",
    "percentage": 0.026619343389529725,
    "effects": [
      {
        "uuid": "00383CC5-E4F7-40BB-8202-2A7F28FABAD0",
        "name": "AmbientLightEffect",
        "effectData": {
          "enabled": true,
          "intensity": 1.9500000000000002,
          "color": {
            "type": "color",
            "value": [
              0.8980392156862745,
              0.8901960784313725,
              1
            ]
          }
        },
        "scrollData": {
          "enabled": false,
          "inverted": false,
          "maxDelta": 1000,
          "inertiaFactor": 0.01,
          "defaultValue": 0.75,
          "minOutput": 0,
          "maxOutput": 3
        }
      },
      {
        "uuid": "C8425EAE-E993-4850-9A57-2C537CA1CCBD",
        "name": "FogEffect",
        "effectData": {
          "enabled": true,
          "density": 0.0011,
          "color": {
            "type": "color",
            "value": [
              1,
              0.796078431372549,
              0.4666666666666667
            ]
          }
        },
        "scrollData": {
          "enabled": false,
          "inverted": false,
          "maxDelta": 1000,
          "inertiaFactor": 0.01,
          "defaultValue": 0,
          "minOutput": 0,
          "maxOutput": 0.1
        }
      }
    ]
  }
]