export default [
  {
    "uuid": "BE1AFE0E-5B93-4757-8C4C-C98DA628566D",
    "name": "",
    "percentage": 0,
    "effects": [
      {
        "uuid": "74990A7F-346A-4A5A-BE5E-527AB8CD9AFC",
        "name": "FovCameraEffect",
        "effectData": {
          "enabled": true,
          "fov": 110
        },
        "scrollData": {
          "enabled": false,
          "inverted": false,
          "maxDelta": 1000,
          "inertiaFactor": 0.01,
          "defaultValue": 45,
          "minOutput": -179,
          "maxOutput": 179
        }
      }
    ]
  },
  {
    "uuid": "3BAF96B9-A1E0-4840-A727-4D4A8190674B",
    "name": "",
    "percentage": 0.4192377495462795,
    "effects": [
      {
        "uuid": "74990A7F-346A-4A5A-BE5E-527AB8CD9AFC",
        "name": "FovCameraEffect",
        "effectData": {
          "enabled": true,
          "fov": 100
        },
        "scrollData": {
          "enabled": false,
          "inverted": false,
          "maxDelta": 1000,
          "inertiaFactor": 0.01,
          "defaultValue": 45,
          "minOutput": -179,
          "maxOutput": 179
        }
      }
    ]
  }
]