export default [
  {
    "uuid": "DA1EF16B-2AAD-4B20-B689-9A077EC83847",
    "name": "",
    "percentage": 0.016666666666666666,
    "effects": [
      {
        "uuid": "74990A7F-346A-4A5A-BE5E-527AB8CD9AFC",
        "name": "FovCameraEffect",
        "effectData": {
          "enabled": true,
          "fov": 60
        },
        "scrollData": {
          "enabled": false,
          "inverted": false,
          "maxDelta": 1000,
          "inertiaFactor": 0.01,
          "defaultValue": 45,
          "minOutput": -179,
          "maxOutput": 179
        }
      }
    ]
  }
]